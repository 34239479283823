module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Boge","short_name":"Boge","start_url":"/global-landing-page/","icon":"src/static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"06f40361e756d4a2f4785bb1d6eacc52"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","en-uk","en-us","en-in","en-sg","en-au","zh-hans","de","de-at","fr","nl-nl","nl-be","fr-be","it","es","es-us","global-landing-page","fr-fr","es-es"],"defaultLanguage":"global-landing-page","generateDefaultLanguagePage":true,"redirect":false,"i18nextOptions":{"lng":"cimode","interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false,"load":"all","cleanCode":true,"lowerCaseLng":true},"pages":[{"matchPath":"/kleine-kompressoren-c-baureihe","languages":["en","de","fr","es"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
